.interesting-wrapper{
    background-image:url('https://i.ibb.co/9yp9nzq/home-Banner.jpg') ;
    background-position: center;
    background-size: cover;
    background-attachment: fixed; 
}
.rotate-animation{ 
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 24px 30px;

}
@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
} 