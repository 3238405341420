@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@1,900&display=swap');
body{ 
} 
.banner-container video{
    position: absolute; 
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.font-poppins{
    font-family: 'Poppins', sans-serif !important;  
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    background: #000;
}